export enum PaddleProductId {
  // Products
  CommercialPlanLifetime = 634695,
  WeddingsPlan = 655090,

  // Subscriptions
  CommercialPlanYearly = 634694,
  CommercialPlanMonthly = 634635,
  CommercialPlanQuarterly = 823949
}

export const allProductIds = [
  PaddleProductId.CommercialPlanQuarterly,
  PaddleProductId.CommercialPlanYearly,
  PaddleProductId.CommercialPlanLifetime,
  PaddleProductId.WeddingsPlan
];
