import { get, set } from 'local-storage';
import { isAndroid, isIOS, osName } from 'react-device-detect';
import { useEffect } from 'react';
import { config } from '../config/config';
import { getParameterByName } from './utils';

interface MarketingValue {
  timestamp: number;
  value: string;
}

export const storeMarketingValue = (key: string, value: string): void => {
  const fullKey = `marketing.${key}`;

  if (value) {
    const now = Math.floor(Date.now() / 1000);
    const data = {
      value,
      timestamp: now
    };
    set<MarketingValue>(fullKey, data);
  }
};
export const marketingParamsToStore = [
  'ref',
  'source',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
];
export const useStoreMarketingValues = (): void => {
  useEffect(() => {
    marketingParamsToStore.forEach((key) => {
      const value = getParameterByName(key, window.location.href);
      if (value) {
        storeMarketingValue(key, value);
      }
    });
  }, []);
};
export const marketingValue: (key: string) => string | undefined = (key): string | undefined => {
  const fullKey = `marketing.${key}`;
  const storedData = get<MarketingValue>(fullKey);
  if (!storedData) {
    return undefined;
  }

  try {
    const { timestamp, value } = storedData;
    const now = Math.floor(Date.now() / 1000);
    const difference = now - timestamp;

    if (difference < 28 * 24 * 3600) {
      return value;
    }
  } catch (e) {
    return '';
  }
  return '';
};

export const getMarketingValues = (): Record<string, string | undefined> => {
  return Object.fromEntries(marketingParamsToStore.map((key) => [key, marketingValue(key)]));
};

export const trackEvent = (event: string, data: Record<any, any>): void => {
  window.gtag('event', event, data);
  window.plausible?.(event, { props: data });
  if (window.fbq) {
    window.fbq('track', event, data);
  }
};

export const trackPageView = (): void => {
  const url = new URL(window.location.href);
  const marketingValues = getMarketingValues();
  Object.keys(marketingValues).forEach((key) => {
    const value = marketingValues[key];
    if (value) {
      url.searchParams.set(key, value);
    }
  });

  const marketingUrl = url.toString();
  console.log(`Page changed: ${marketingUrl}`);

  // https://plausible.io/docs/custom-query-params
  window.plausible?.('pageview', { u: marketingUrl });
  if (window.gtag) {
    window.gtag('config', config.gTagID, { page_path: marketingUrl });
  }
};

export type ProductTypes =
  | 'commercial-plan-yearly'
  | 'commercial-plan-lifetime'
  | 'commercial-plan-monthly'
  | 'commercial-plan-quarterly'
  | 'home-plan-quarterly'
  | 'home-plan-yearly'
  | 'home-plan-lifetime'
  | 'weddings-plan';
export const trackPurchaseStarted = (plan: ProductTypes): void => {
  trackEvent('Purchase Started', {
    plan
  });
};

export const trackPurchaseCompleted = (data: Record<any, any>): void => {
  trackEvent('Purchase Completed', data);
};

export const trackDownload = (platform: 'android' | 'ios' | 'windows' | 'linux' | 'macos'): void => {
  trackEvent('Download started', {
    platform
  });
};

export const trackDownloadAuto = (): void => {
  if (isAndroid) {
    trackDownload('android');
  } else if (isIOS) {
    trackDownload('ios');
  } else if (osName === 'Windows') {
    trackDownload('windows');
  } else if (osName === 'Mac OS') {
    trackDownload('macos');
  } else {
    trackDownload('linux');
  }
};

export const trackBlogCTA = (data: Record<string, unknown>): void => {
  trackEvent('Blog CTA Clicked', data);
};
