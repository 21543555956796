import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import { withIntlApp } from '@moxy/next-intl';
import Head from 'next/head';
import Script from 'next/script';
import { Base } from '../src/layouts/BaseComponent';
import { reduxStore } from '../src/redux/configureStore';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'animate.css/animate.min.css';
import '../src/assets/styles/main.scss';
import '../src/assets/styles/fonts.scss';
import { config } from '../src/config/config';

class MyApp extends App {
  render(): JSX.Element {
    return (
      <Provider store={reduxStore}>
        <Head>
          <meta
            name="viewport"
            key="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
        </Head>
        {config.plausible && (
          <Script
            src={`${config.plausible.url}js/script.manual.js`}
            strategy="afterInteractive"
            data-domain={config.plausible.siteId}
          />
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Base {...this.props} Component={this.props.Component} pageProps={this.props.pageProps} />
      </Provider>
    );
  }
}

const loadLocale = async (locale = 'en') => {
  const module = await import(/* webpackChunkName: "intl-messages" */ `./../src/lang/${locale}.json`);
  return module.default;
};
// eslint-disable-next-line import/no-default-export
export default withIntlApp(loadLocale)(MyApp);
