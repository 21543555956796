import { AsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
export type ApiState<ResultType> = {
  loading: boolean;
  error: SerializedError | null;
  result: ResultType | null;
};

function onApiPending<ResultType>(state: ApiState<ResultType>) {
  state.loading = true;
  state.error = null;
  state.result = null;
}
function onApiRejected<ResultType>(
  state: ApiState<ResultType>,
  action: PayloadAction<unknown, string, unknown, SerializedError>
) {
  state.loading = false;
  state.error = action.error;
  state.result = null;
}
function onApiFulfilled<ResultType>(state: ApiState<ResultType>, action: PayloadAction<ResultType>) {
  state.loading = false;
  state.error = null;
  state.result = action.payload;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createApiSlice<ResultType, ThunkArg = void>(
  name: string,
  thunk: AsyncThunk<ResultType, ThunkArg, {}>,
  initialResult: ResultType | null = null
) {
  return createSlice<ApiState<ResultType>, {}>({
    name,
    initialState: {
      loading: false,
      error: null,
      result: initialResult
    },
    reducers: {},
    extraReducers: {
      [thunk.pending.type]: onApiPending,
      [thunk.rejected.type]: onApiRejected,
      [thunk.fulfilled.type]: onApiFulfilled
    }
  });
}
