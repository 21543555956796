import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { paddleReducer } from './paddle/slice';

const rootReducer = combineReducers({
  paddle: paddleReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export const reduxStore = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof reduxStore.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
