export enum ConfigEnvironment {
  LOCAL,
  STAGING,
  PRODUCTION
}

export interface Config {
  env: ConfigEnvironment;
  release: string;
  appProtocol: string;
  domain: string;
  gTagID: string;
  isDebug: boolean;
  sentryDSN?: string;
  API: string;
  serverAPI: string;
  strapi: { token: string; url: string };
  imgixHost: string;
  plausible?: {
    siteId: string;
    url: string;
  };
}
